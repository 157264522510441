export const announceConfig =
    process.env.REACT_APP_ENV === 'production'
        ? []
        : [
              {
                  text: 'Testnet only, last update time: 2023.01.31',
                  icon: '',
                  type: 'link',
                  linkText: 'Check more.',
                  onClick: null,
                  index: 0,
              },
          ];
