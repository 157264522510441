import React from 'react';
import { BoxProps, HStack, Image } from '@chakra-ui/react';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useMemo } from 'react';
import { getChain } from '../../../config/chains';
import tokens from '../../../config/tokens';
import { TokenSymbol } from '../../../types/mod';
import CustomButton from '../../CustomButton/CustomButton';

type Props = {
    expand?: boolean;
} & BoxProps;

const IZiLinkButton: React.FC<Props> = (props) => {
    const { expand, ...rest } = props;
    const { chainId } = useWeb3WithDefault();
    const link = useMemo(() => {
        const scanUrl = getChain(chainId)?.scanUrl;
        const iZiAddr = tokens[TokenSymbol.IZI].contracts[chainId]?.address;
        return scanUrl + 'token/' + iZiAddr;
    }, [chainId]);

    return (
        <CustomButton
            variant="lightGreen"
            w="34px"
            h="34px"
            borderRadius="3px"
            text={
                <HStack w="100%" mx="auto">
                    <Image w="19px" h="19px" src={process.env.PUBLIC_URL + '/assets/tokens/iZiWhite.svg'} ml={expand ? '12px' : '8px'} />
                </HStack>
            }
            onClick={() => {
                window.open(link);
            }}
            {...rest}
        ></CustomButton>
    );
};

export default IZiLinkButton;
