import { HStack, Image, Stack, Text, useColorMode } from '@chakra-ui/react';
import { useState } from 'react';
import { i_h3, i_text_copy } from '../../../style';
import placeholder from '../../../assets/placeholder.png';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/Card/Card';

type PartnerCardProps = {
    titleImgSrc?: string;
    title?: string;
    content?: string;
    link?: string;
};
export const PartnerCard: React.FC<PartnerCardProps> = (props) => {
    const { titleImgSrc, title, content, link } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const [cardFlow, setCardFlow] = useState(false);

    return (
        <Card
            className={cardFlow ? 'cardFlow' : 'cardLeave'}
            h="247px"
            bg={colorTheme('ffffff', '#232F23')}
            p="21px"
            cursor="pointer"
            onClick={() => {
                window.open(link);
            }}
            onMouseOver={() => {
                setCardFlow(true);
            }}
            onMouseLeave={() => {
                setCardFlow(false);
            }}
        >
            <Stack h="100%" spacing="25px">
                <HStack spacing="15px">
                    <Image boxSize="53px" src={titleImgSrc ? titleImgSrc : placeholder} fallbackSrc={titleImgSrc}></Image>
                    <Text className={i_h3} isTruncated={true}>
                        {title && t(title)}
                    </Text>
                </HStack>
                <Text className={i_text_copy} noOfLines={5}>
                    {content && t(content)}
                </Text>
                <Image
                    mt="auto !important"
                    ml="auto !important"
                    boxSize="15px"
                    src="/assets/portal/ArrowRight.svg"
                    fallbackSrc="/assets/portal/ArrowRight.svg"
                ></Image>
            </Stack>
        </Card>
    );
};
