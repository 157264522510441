import React from 'react';
import { Text, useColorMode, BoxProps, Flex } from '@chakra-ui/react';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { i_text_copy, i_text_copy_bold } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { formatNumber } from '../../../../utils/tokenMath';
import { useTranslation } from 'react-i18next';

type LiquidityProps = {
    liquidity: string | number | null;
    empty: boolean;
    onAdd: () => void;
    onRemove: () => void;
    onBurn: () => void;
} & BoxProps;

export const Liquidity: React.FC<LiquidityProps> = ({ liquidity, onAdd, onRemove, empty, onBurn, ...rest }) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    return (
        <Flex
            w={{ base: 'unset', sm: '280px' }}
            h="68px"
            bg={colorTheme('#F9F9FB', '#181F16')}
            direction="row"
            alignItems="center"
            pr={{ base: '10px', sm: '20px' }}
            {...rest}
        >
            <Flex flex={20} direction="column" alignItems="left" mt="-10px" pl={{ base: '0px', sm: '10px' }}>
                <Text pl="12px" pt="10px" className={i_text_copy}>
                    {t('Liquidity')}
                </Text>

                <Text pl="12px" pt="10px" className={i_text_copy_bold}>
                    {'$ ' + formatNumber(Number(liquidity))}
                </Text>
            </Flex>

            <CustomButton variant="tertiary" text={t('Add')} h="30px" w="60px" fontClass={i_text_copy_bold} onClick={onAdd} />
            {empty ? (
                <CustomButton variant="orange" text={t('Burn')} h="30px" w="70px" ml="10px" fontClass={i_text_copy_bold} onClick={onBurn} />
            ) : (
                <CustomButton
                    variant="orange"
                    text={t('Remove')}
                    h="30px"
                    w="70px"
                    ml="10px"
                    fontClass={i_text_copy_bold}
                    onClick={onRemove}
                />
            )}
        </Flex>
    );
};
