import { Circle, HStack, Stack, Text, Image, useColorMode } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SHOW_REFRESH_PAGE } from '../../../config/bizConfig';
import useLayoutType from '../../../hooks/useLayoutType';
import { i_text_copy_bold } from '../../../iZUMi-UI-toolkit/src/style';
import { LayoutStateEnum } from '../../../state/models/pageLayout/pageLayout';
import { RootState } from '../../../state/store';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';

export const NetworkConnect: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { block } = useSelector((state: RootState) => state);
    const layoutType = useLayoutType();
    const location = useLocation();

    const [showConnect, seShowConnect] = useState(true);
    const isShowFresh = SHOW_REFRESH_PAGE.includes(location.pathname);
    useEffect(() => {
        seShowConnect(true);
    }, [block.showError]);

    return (
        <Stack>
            {block.showError && showConnect && (
                <Stack
                    w="340px"
                    pos="fixed"
                    bottom="22px"
                    right={layoutType === LayoutStateEnum.header ? '24px' : '102px'}
                    border="1px solid #FF9999"
                    borderRadius="10px"
                    bg={colorTheme('#FFFFFF', '#2C2647')}
                    zIndex="8"
                    boxShadow={colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', ' 0px 0px 7px rgba(20, 8, 32, 0.25)')}
                    spacing="4px"
                >
                    <HStack
                        w="100%"
                        h="40px"
                        bg={colorTheme(
                            'linear-gradient(90.96deg, #FF7373 9.65%, rgba(255, 119, 119, 0.63) 137.73%);',
                            'linear-gradient(90.96deg, rgba(255, 111, 111, 0.78) 9.65%, rgba(255, 119, 119, 0.65) 137.73%)'
                        )}
                        borderRadius="10px 10px 0px 0px"
                        pl="25px"
                        pr="15px"
                        justifyContent="space-between"
                    >
                        <HStack spacing="5px">
                            <Image
                                boxSize="18px"
                                src="/assets/networkConnect/warning.svg"
                                fallbackSrc="/assets/networkConnect/warning.svg"
                            ></Image>
                            <Text className={i_text_copy_bold} fontSize="14px" color="#FFFFFF">
                                Network Warning
                            </Text>
                        </HStack>
                        <Image
                            boxSize="10px"
                            src="/assets/networkConnect/whiteClose.svg"
                            fallbackSrc="/assets/networkConnect/whiteClose.svg"
                            cursor="pointer"
                            onClick={() => {
                                seShowConnect(false);
                            }}
                        ></Image>
                    </HStack>
                    <Stack h="86px" pl="25px" pr="15px" pt="13px">
                        <Text className={i_text_copy} color={colorTheme('#795252', '#DDBDBD')}>
                            Seems to have lost connection with the blockchain, please refresh the page.
                        </Text>
                        <HStack pt="8px" ml="auto !important">
                            <Text className={i_text_copy} color={colorTheme('#A35151', '#E47777')}>
                                {block.currentBlock}
                            </Text>
                            <Circle w="10px" h="10px" bg={colorTheme('#FF6C6C', '#FF5252')}></Circle>
                        </HStack>
                    </Stack>
                </Stack>
            )}

            {!block.showError && !isShowFresh && (
                <HStack
                    pos="fixed"
                    bottom="22px"
                    right={layoutType === LayoutStateEnum.header ? '24px' : '102px'}
                    zIndex="8"
                    border="1px solid #32E344"
                    borderRadius="10px"
                    p="7px 12px"
                    spacing="4px"
                    bg={colorTheme('#FEFEFE', '#2C2647')}
                >
                    <Text className={i_text_copy} color="#58CC5D">
                        {block.currentBlock}
                    </Text>
                    <Circle w="10px" h="10px" bg="#32E344"></Circle>
                </HStack>
            )}
            {!block.showError && isShowFresh && (
                <HStack
                    pos="fixed"
                    w="163px"
                    bottom="22px"
                    right={layoutType === LayoutStateEnum.header ? '24px' : '102px'}
                    zIndex="8"
                    border={colorTheme('1px solid #79E13C', '1px solid #79E13C')}
                    borderRadius="10px"
                    justifyContent="center"
                    p="7px 12px"
                    spacing="4px"
                >
                    <Image
                        boxSize="15px"
                        src={colorTheme('/assets/networkConnect/fresh.svg', '/assets/networkConnect/fresh.svg')}
                        fallbackSrc={colorTheme('/assets/networkConnect/fresh.svg', '/assets/networkConnect/fresh.svg')}
                    ></Image>
                    <Text w="90px" className={i_text_copy} color={colorTheme('#79E13C', '#79E13C')}>
                        refresh in {block.remainSeconds}s
                    </Text>
                    <Circle w="10px" h="10px" bg={colorTheme('#79E13C', '#79E13C')}></Circle>
                </HStack>
            )}
        </Stack>
    );
};

export default NetworkConnect;
