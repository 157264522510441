import { links } from '../links';

export const config = {
    pages: [
        {
            name: 'Swap',
            img: 'Trade.svg',
            address: '/swap',
            marker: 'new',
            inTabBar: true,
            inHeader: true,
            children: [],
        },
        {
            name: 'Pro',
            img: 'Pro.svg',
            address: '/pro',
            inTabBar: false,
            inHeader: true,
            children: [],
        },
        {
            name: 'Pools',
            img: 'Pools.svg',
            address: '/pools',
            inTabBar: true,
            inHeader: true,
            children: [],
        },
        {
            name: 'Liquidity',
            img: 'Liquidity.svg',
            address: '/liquidity',
            inTabBar: true,
            inHeader: true,
            children: [],
        },
        // {
        //     name: 'iPoints',
        //     img: 'iPoints.svg',
        //     address: '/iPoints',
        //     inTabBar: false,
        //     inHeader: false,
        //     children: [],
        // },
        {
            name: 'Farm',
            img: 'Farm.svg',
            address: '/farm/dynamic',
            inTabBar: true,
            inHeader: true,
            children: [],
        },
        {
            name: 'Portal',
            img: 'Portal.svg',
            address: '/portal',
            inTabBar: false,
            inHeader: false,
            children: [],
        },
        {
            name: 'Bridge',
            img: 'Bridge.svg',
            address: '/bridge',
            inTabBar: false,
            inHeader: false,
            children: [],
        },

        {
            name: 'Analytics',
            img: 'Analytics.svg',
            address: links.analyticLink,
            inTabBar: false,
            inHeader: false,
            children: [],
        },
    ],
} as { pages: PageConfig[] };
