import { BoxProps, VStack, Text, useColorMode, HStack, Divider, Stack, Flex, Center, Box, Tooltip, Image } from '@chakra-ui/react';
import { ChevronDownIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useIsMobile from '../../../hooks/useIsMobile';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { ResponseIziSwapPoolSummaryRecord } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { MultiPageProps } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';
import { RootState, RootDispatch } from '../../../state/store';
import { i_text_copy } from '../../../style';
import { TokenSymbol } from '../../../types/mod';
import { getColorThemeSelector, toFeeNumber } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';
import { Pagination } from '../../OrderHistory/components/Pagination';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../hooks/useTokenListFormatted';
import { tokenAddr2Token } from '../../../config/tokens';
import { FeeRate } from './FeeRate';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { Loading, LoadingEnum } from '../../components/Loading';
import { FEE_CHARGE_PERCENT } from '../../../config/bizConfig';
import { TokenIcons } from './TokenIcons';
import Info from '../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { links } from '../../../config/links';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/Card/Card';

type PoolListProps = MultiPageProps<ResponseIziSwapPoolSummaryRecord> & { networkLoading: boolean } & BoxProps;

export const PoolList: React.FC<PoolListProps> = (props) => {
    const { setting, pageData, setCurrentPage, setPageSize, networkLoading, children, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const history = useHistory();
    const { pools } = useSelector((state: RootState) => state);
    const dispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch);
    const { tokenList } = useTokenListFormatted();
    const getToken = (address: string) => {
        const tempToken = {
            ...tokenList.find((e) => e.address.toLowerCase() === address.toLowerCase()),
        };
        const findToken = tokenAddr2Token(address, chainId);
        if (findToken.name) {
            return findToken;
        }

        return tempToken;
    };
    const trStyle = {
        className: { i_text_copy },
        fontSize: '12px',
        color: colorTheme('#85BA84', '#95BA84'),
    } as unknown as BoxProps;

    const tab = [
        ['Name', '230px'],
        ['Liquidity', '145px'],
        ['Range', '120px'],
        ['Volume(24h)', '145px'],
        ['APR', '120px'],
    ];

    const InitFilter = () => {
        if (pools.poolSetting.orderBy === '-major') {
            return [false, true, false, false, false, false];
        } else if (pools.poolSetting.orderBy === '-vol_day') {
            return [false, false, false, true, false, false];
        } else {
            return [false, false, false, false, false, false];
        }
    };

    const [FilterIndex, setFilterIndex] = useState(InitFilter);
    const [clickFilterIndex, setClickFilterIndex] = useState(InitFilter);

    const showFilter = (type: string) => {
        if (type === 'Name') {
            return false;
        } else {
            return true;
        }
    };

    const onSortByChange = useCallback(
        (e: any) => {
            const poolsControl = { ...pools.poolsControl };
            poolsControl.sortBy = e;
            dispatch.pools.setPoolsControl(poolsControl);
        },
        [pools, dispatch]
    );

    return (
        <Card variant="base" w="100%" alignItems="left" p={{ base: '0px', sm: '30px 64px 30px 64px' }} {...rest}>
            <VStack spacing="14px" alignItems="left">
                {children}
                {!isMobile && (
                    <HStack spacing="20px">
                        {tab.map((e: any, i: any) => {
                            return (
                                <Flex
                                    key={i}
                                    w={e[1]}
                                    alignItems="center"
                                    onMouseOver={() => {
                                        FilterIndex[i] = true;
                                        setFilterIndex([...FilterIndex]);
                                    }}
                                    onMouseLeave={() => {
                                        if (!clickFilterIndex[i]) {
                                            FilterIndex[i] = false;
                                            setFilterIndex([...FilterIndex]);
                                        }
                                    }}
                                >
                                    <Text {...trStyle}>{t(e[0])}</Text>
                                    {showFilter(e[0]) && (
                                        <Flex direction="column" ml="10px">
                                            {e[0] === 'APR' ? (
                                                <HStack>
                                                    <Tooltip
                                                        label={t(
                                                            'APR is calculated based on the recommended range, which balances impermanent loss and returns. For example, when the recommended range is -20% to +20%, we only calculate the liquidity and trades generated within this range. APR is a 24-hour sliding average that updates every 20 minutes.'
                                                        )}
                                                    >
                                                        {<InfoOutlineIcon />}
                                                    </Tooltip>
                                                    <ChevronDownIcon
                                                        opacity={FilterIndex[i] ? 1 : 0}
                                                        cursor="pointer"
                                                        onClick={() => {
                                                            const tempFilter = new Array(tab.length).fill(false);
                                                            tempFilter[i] = true;
                                                            setFilterIndex([...tempFilter]);
                                                            setClickFilterIndex([...tempFilter]);
                                                            onSortByChange(e[0]);
                                                        }}
                                                    />
                                                </HStack>
                                            ) : e[0] === 'Range' ? (
                                                <Tooltip
                                                    label={t(
                                                        'The range represents the recommended range for adding liquidity, which balances impermanent loss and returns. Note that we do not make recommendations for all pools. If the value of range is infinite, it means that we do not provide a recommendation.'
                                                    )}
                                                >
                                                    {<InfoOutlineIcon />}
                                                </Tooltip>
                                            ) : (
                                                <ChevronDownIcon
                                                    opacity={FilterIndex[i] ? 1 : 0}
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        const tempFilter = new Array(tab.length).fill(false);
                                                        tempFilter[i] = true;
                                                        setFilterIndex([...tempFilter]);
                                                        setClickFilterIndex([...tempFilter]);
                                                        onSortByChange(e[0]);
                                                    }}
                                                />
                                            )}
                                        </Flex>
                                    )}
                                </Flex>
                            );
                        })}
                    </HStack>
                )}
                {isMobile ? <Box h="0px"></Box> : <Divider pt="3px" mb="17px !important" />}

                {networkLoading ? (
                    <Center w="100%" minH="1680px">
                        <Loading variant={LoadingEnum.purple} text="Loading..." pt="100px" pb="30px" />
                    </Center>
                ) : (
                    pageData.data.map((item: ResponseIziSwapPoolSummaryRecord, i: number) => {
                        const findAprItem = pools.poolAprList.find((i) => i.pool_address === item.address);
                        return (
                            <Stack
                                key={i}
                                direction={{ base: 'column', sm: 'row' }}
                                alignItems={{ base: 'start', sm: 'center' }}
                                spacing={{ base: '14px', sm: '20px' }}
                            >
                                <HStack
                                    w={{ base: '100%', sm: '230px' }}
                                    mt="2px !important"
                                    justifyContent={{ base: 'start', sm: 'center' }}
                                    px={{ base: '30px', sm: '0px' }}
                                >
                                    <TokenIcons
                                        tokenA={getToken(item.tokenX_address) as TokenInfoFormatted}
                                        tokenB={getToken(item.tokenY_address) as TokenInfoFormatted}
                                    />
                                    <FeeRate
                                        tokenA={item.tokenX as TokenSymbol}
                                        tokenB={item.tokenY as TokenSymbol}
                                        feeTier={item.fee}
                                        initialToggle={false}
                                    />
                                </HStack>
                                <Stack
                                    w={{ base: '100%', sm: 'unset' }}
                                    bg={{
                                        base: colorTheme(
                                            'linear-gradient(180deg, #F5F7FF 0%, rgba(245, 247, 255, 0.59) 100%)',
                                            'rgba(106, 106, 106, 0.05)'
                                        ),
                                        sm: 'unset',
                                    }}
                                    px={{ base: '33px', sm: '0px' }}
                                    py={{ base: '11px', sm: '0px' }}
                                    direction={{ base: 'column', sm: 'row' }}
                                    spacing="20px"
                                >
                                    <HStack
                                        w={{ base: '100%', sm: '145px' }}
                                        justifyContent={{ base: 'space-between', sm: 'start' }}
                                        px={{ base: '20px', sm: '0px' }}
                                    >
                                        {isMobile && <Text {...trStyle}>{tab[1][0]}</Text>}
                                        <Text className={i_text_copy} fontSize="13px !important">
                                            {'$ ' + formatNumber(item.tvl, 2, 2)}
                                        </Text>
                                    </HStack>
                                    <HStack
                                        w={{ base: '100%', sm: '120px' }}
                                        justifyContent={{ base: 'space-between', sm: 'start' }}
                                        px={{ base: '20px', sm: '0px' }}
                                    >
                                        {isMobile && <Text {...trStyle}>{tab[2][0]}</Text>}
                                        <Text className={i_text_copy} fontSize="13px !important">
                                            {findAprItem ? findAprItem?.leftRange + '%' + ' ~ ' + findAprItem?.rightRange + '%' : '∞'}
                                        </Text>
                                    </HStack>
                                    <HStack
                                        w={{ base: '100%', sm: '145px' }}
                                        justifyContent={{ base: 'space-between', sm: 'start' }}
                                        px={{ base: '20px', sm: '0px' }}
                                    >
                                        {isMobile && <Text {...trStyle}>{tab[3][0]}</Text>}
                                        <Text className={i_text_copy} fontSize="13px !important">
                                            {'$ ' + formatNumber(item.volDay, 2, 2)}
                                        </Text>
                                    </HStack>
                                    <HStack
                                        w={{ base: '100%', sm: '120px' }}
                                        justifyContent={{ base: 'space-between', sm: 'start' }}
                                        px={{ base: '20px', sm: '0px' }}
                                    >
                                        {isMobile && <Text {...trStyle}>{tab[4][0]}</Text>}
                                        <Text
                                            w="80px"
                                            className={i_text_copy}
                                            fontSize="13px !important"
                                            textAlign={{ base: 'end', sm: 'start' }}
                                        >
                                            {findAprItem
                                                ? formatNumber(findAprItem.slideApr * 100, 2, 2, true) + '%'
                                                : (
                                                      (item.volDay * toFeeNumber(item.fee) * 365 * (100 - FEE_CHARGE_PERCENT)) /
                                                      100 /
                                                      item.tvl
                                                  ).toFixed(2) + '%'}
                                        </Text>
                                    </HStack>
                                    <HStack spacing="17px" justifyContent={{ base: 'center', sm: 'start' }}>
                                        <Image
                                            w="24px"
                                            h="20px"
                                            src={colorTheme(
                                                '/assets/trade/limitOrderDashboard.svg',
                                                '/assets/trade/darklimitOrderDashboard.svg'
                                            )}
                                            fallbackSrc={colorTheme(
                                                '/assets/trade/limitOrderDashboard.svg',
                                                '/assets/trade/darklimitOrderDashboard.svg'
                                            )}
                                            cursor="pointer"
                                            onClick={() => {
                                                window.open(
                                                    links.analyticLink + '/pool?chainId=' + chainId + '&poolAddress=' + item.address
                                                );
                                            }}
                                        ></Image>

                                        <CustomButton
                                            variant="primary2"
                                            w={{ base: 'unset', xl: '121px' }}
                                            h={{ base: '34px', sm: '38px' }}
                                            text={t('Add Liquidity') + ' >'}
                                            className={i_text_copy}
                                            borderRadius={{ base: '10px', sm: '3px' }}
                                            px="10px"
                                            py="8px"
                                            onClick={() => {
                                                // clear mint form cache first
                                                dispatch.iZiSwapMintOrIncForm.clearForm();
                                                history.push(
                                                    `/add-liquidity/custom?token0=${item.tokenX_address}&token1=${item.tokenY_address}&fee=${item.fee}&chainId=${chainId}&range=${findAprItem?.rightRange}`
                                                );
                                                //TODO: WETH -> ETH explicitly
                                            }}
                                        ></CustomButton>
                                    </HStack>
                                </Stack>
                            </Stack>
                        );
                    })
                )}

                <Pagination
                    totalPage={pageData.totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mx={{ base: '40px !important', sm: '0px !important' }}
                    mt={{ base: '30px !important', sm: '40px !important' }}
                    mb={{ base: '30px !important', sm: '0px !important' }}
                />
            </VStack>
        </Card>
    );
};
