import { BoxProps, VStack } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { POOLS_APR_RANGE } from '../../config/bizConfig';
import { useRematchDispatch } from '../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import { ToastType, useCustomToast } from '../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { AprTypeEnum, getLiquidityApr, RequestLiquidityApr } from '../../net/iZUMi-endpoints/src/restful/izumiLiquidity';
import {
    ResponseIziSwapPoolSummaryRecord,
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
} from '../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { MultiPageData } from '../../net/iZUMi-endpoints/src/restful/types/multiPage';
import { PoolAprList } from '../../state/models/pools/pools';
import { RootState, RootDispatch } from '../../state/store';
import { PoolList } from './components/PoolList';

type PoolsBlockProps = BoxProps;

export const PoolsBlock: React.FC<PoolsBlockProps> = (props) => {
    const { ...rest } = props;
    const { chainId } = useWeb3WithDefault();
    const { t } = useTranslation();
    const toast = useCustomToast();

    const { pools } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));

    const [poolSummaryData, setPoolSummaryData] = useSetState<MultiPageData<ResponseIziSwapPoolSummaryRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });
    const [networkLoading, setNetworkLoading] = useState(false);
    const [tempAprList, setTempAprList] = useState([] as PoolAprList[]);

    const getApr = async () => {
        const promisesList: Promise<any>[] = [];
        if (!POOLS_APR_RANGE[chainId]) {
            return;
        }
        POOLS_APR_RANGE &&
            POOLS_APR_RANGE[chainId].map((item: any) => {
                promisesList.push(getLiquidityApr({ address: item.pool_address, chainId: chainId, type: AprTypeEnum.Pool }));
            });
        return await Promise.all(promisesList);
    };

    useEffect(() => {
        setTempAprList([]);
        getApr().then((res) => {
            if (!res) {
                return;
            }
            const l = res.reduce((acc, r, index) => {
                const item = POOLS_APR_RANGE[chainId][index];
                const data = r.data.is_success ? r.data.data : [];
                const result = data.find((i: any) => i.leftRange === item.leftRange / 100 && i.rightRange === item.rightRange / 100);
                if (result) {
                    return [
                        ...acc,
                        {
                            pool_address: item.pool_address,
                            apr: result.apr,
                            leftRange: item.leftRange,
                            rightRange: item.rightRange,
                            slideApr: result.slideApr,
                        },
                    ];
                }
                return acc;
            }, []);
            setTempAprList(l);
        });
    }, [chainId]);

    useEffect(() => {
        setNetworkLoading(true);
        dispatch.pools.setPoolAprList(tempAprList);

        getIziSwapGenericSummaryRecord<ResponseIziSwapPoolSummaryRecord[]>({
            page: pools.poolSetting.page,
            page_size: pools.poolSetting.pageSize,
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_LATEST,
            order_by: pools.poolSetting.orderBy,
        })
            .then((r) => {
                // TODO loading status
                const data = r.data.is_success ? r.data.data : [];
                const totalPage = r.data.is_success ? Math.ceil(r.data.total / pools.poolSetting.pageSize) : 1;
                setPoolSummaryData({ data, totalPage });
            })
            .catch((e) => {
                toast(ToastType.error, typeof e === 'string' ? e : e.message);
            })
            .finally(() => {
                setNetworkLoading(false);
            });
    }, [pools.poolSetting, chainId, tempAprList]);

    const poolListFiltered = useMemo(() => {
        const control = pools.poolsControl;
        const filteredPools = _.cloneDeep(poolSummaryData);
        let ff = [...poolSummaryData.data];

        if (control.searchKey) {
            ff = ff.filter((pool) => {
                return (
                    pool.tokenX.toLowerCase().includes(control.searchKey.toLowerCase()) ||
                    pool.tokenY.toLowerCase().includes(control.searchKey.toLowerCase())
                );
            });
        }
        if (control.sortBy) {
            if (control.sortBy === 'Liquidity') {
                ff = ff.sort((a, b) => {
                    const av = a.tvl;
                    const bv = b.tvl;
                    return bv - av;
                });
            } else if (control.sortBy === 'Volume(24h)') {
                ff = ff.sort((a, b) => {
                    return b.volDay - a.volDay;
                });
            } else if (control.sortBy === 'APR') {
                ff = ff.sort((a, b) => {
                    return (b.volDay * b.fee * 365) / b.tvl - (a.volDay * a.fee * 365) / a.tvl;
                });
            }
        }
        filteredPools.data = ff;
        return filteredPools;
    }, [poolSummaryData, pools.poolsControl]);

    return (
        <VStack
            w={{ base: '100%', sm: '1150px' }}
            alignItems="left"
            spacing="20px"
            mb={{ base: '60px !important', sm: '0px !important' }}
            {...rest}
        >
            <PoolList
                setting={pools.poolSetting}
                pageData={poolListFiltered}
                setCurrentPage={(page: number) => dispatch.pools.setPoolSetting({ ...pools.poolSetting, page })}
                setPageSize={(pageSize: number) => dispatch.pools.setPoolSetting({ ...pools.poolSetting, pageSize: Number(pageSize) })}
                networkLoading={networkLoading}
            />
        </VStack>
    );
};
